.app-header {
	position: sticky;
	top: 0;
	background-color: #f5f6f1;
	z-index: 100;
	height: 3rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	color: #2b2e34;
	padding: 0.5rem 2rem;
	/* -webkit-box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.75); */
	-webkit-box-shadow: 0 2px 5px 0 #e1e2de;
	-moz-box-shadow: 0 2px 5px 0 #e1e2de;
	box-shadow: 0 2px 5px 0 #e1e2de;
}

.header-logo {
	width: 150px;
	max-width: 100%;
	height: auto;
}

.lang select {
	color: #2b2e34;
	font-size: 1rem;
	font-weight: 500;
	background-color: transparent;
	cursor: pointer;
	outline: none;

	border-radius: 0;
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	border: none;
	line-height: 1.5em;
	padding: 0.5em 2em 0.5em 1em;

	background: linear-gradient(45deg, transparent 50%, #2b2e34 50%),
		linear-gradient(135deg, #2b2e34 50%, transparent 50%),
		linear-gradient(to right, transparent, transparent);
	background-position: calc(100% - 21px) calc(1em + 2px),
		calc(100% - 16px) calc(1em + 2px), 100% 0;
	background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
	background-repeat: no-repeat;
}
