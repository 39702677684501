.loadin-spinner-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2rem;
}

.loading-spinner {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: conic-gradient(#0000 10%, #61246a);
	mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
	-webkit-mask: radial-gradient(
		farthest-side,
		#0000 calc(100% - 6px),
		#000 0
	);
	animation: s3 1s infinite linear;
}
@keyframes s3 {
	to {
		transform: rotate(1turn);
	}
}

.loading-message {
	margin-left: 1rem;
	font-size: 1.25rem;
	color: #2b2e34;
}
