.message-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	border: 1px solid #f3f3f3;
	border-left-width: 3px;
	border-radius: 4px;
	margin: 2rem auto;
	padding: 1rem;
	max-width: 500px;
}

.message-wrapper.loading {
	background-color: #fbe2ff;
	border-color: #f6baff;
	border-left-color: #61246a;
}

.message-wrapper.error {
	background-color: #fff0ef;
	border-color: #fccbc5;
	border-left-color: #ff5757;
}

.icon {
	color: #2b2e34;
	font-size: 2rem;
	margin-right: 1rem;
}

.message-wrapper.loading .icon {
	color: #2b2e34;
}

.message-wrapper.error .icon {
	color: #ff5757;
}

.text {
	color: #2b2e34;
	font-size: 1.25rem;
}

.message-wrapper.loading .text {
	color: #2b2e34;
}

.message-wrapper.error .text {
	color: #ff5757;
}
