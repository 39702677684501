@import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f5f6f1;
	/* background-color: #f3f3f3; */
}

:root {
	--primary: #3b778b;
	--sjs-primary-backcolor-dark: #3b778b;
	--sjs-font-questiontitle-color: #000000;
	--sjs-font-questiondescription-color: #000000;
	--sjs-font-editorfont-placeholdercolor: #000000;
}

.App .sd-root-modern {
	background-color: #f5f6f1;
}

/* BOOTSTRAP-DATEPICKER */

.sd-input.sd-input:read-only {
	color: var(--sjs-general-forecolor, var(--foreground, #161616));
	box-shadow: none;
	transition: none;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.input-group-append {
	position: absolute;
	right: 1rem;
	top: 0.75rem;
}

.input-group > .widget-datepicker {
	padding-right: 3rem;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover {
	background-color: #bfdfea;
	background-image: none;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover {
	background-color: #3b778b;
	background-image: none;
}

.datepicker table {
	width: 100%;
}

.datepicker .next,
.datepicker .prev {
	height: 2.5rem;
	font-size: 1.5rem;
}
.datepicker tfoot tr th.today,
.datepicker tfoot tr th.clear {
	height: 2rem;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
	.datepicker {
		min-width: 300px;
	}
}

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) { ... } */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.datepicker {
		min-width: 350px;
	}
}
